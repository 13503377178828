import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  // 登录页
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/Login/login.vue')
  },
  {
    path: '/single',
    name: 'LoginSingle',
    component: () => import('@/views/Login/loginSingle.vue')
  },
  // 注册页
  {
    path: '/enroll',
    name: 'Enroll',
    component: () => import('../views/Enroll/enroll.vue'),
    // hidden: true
  },
  // 首页
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home/home.vue')
  },
  // 文献查找
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/Search/search.vue')
  },
  // 英文查重
  {
    path: '/dissertation',
    name: 'Dissertation',
    component: () => import('@/views/Dissertation/dissertation.vue')
  },
  // 语法校对
  // {
  //   path: '/grammar',
  //   name: 'Grammar',
  //   component: () => import('@/views/Dissertation/check_proofread/proofread.vue')
  // },
  // 文档翻译
  {
    path: '/translation',
    name: 'Translation',
    component: () => import('@/views/Translation/translation.vue')
  },
  // 学术推送
  {
    path: '/journalsDetail',
    name: 'JournalsDetail',
    component: () => import('@/views/Academic/component/detail.vue')
  },
  // 期刊列表
  {
    path: '/journalsList',
    name: 'JournalsList',
    component: () => import('@/views/Academic/component/list.vue')
  },
  // 期刊详情
  {
    path: '/academic',
    name: 'Academic',
    component: () => import('@/views/Academic/academic.vue')
  },
  // 文章详情
  {
    path: '/essay',
    name: 'Essay',
    component: () => import('@/views/Academic/component/essayDetail.vue')
  },
  // 引文追踪
  {
    path: '/citation',
    name: 'Citation',
    component: () => import('@/views/Citation/citation.vue')
  },
  // 英文文献引文追踪
  {
    path: '/citationEn',
    name: 'CitationEn',
    component: () => import('@/views/Citation/citationTraceEn.vue')
  },
  // 引文追踪任务列表
  {
    path: '/citationList',
    name: 'CitationList',
    component: () => import('@/views/Citation/citationTraceList.vue')
  },
  // 引文追踪英文任务列表
  {
    path: '/citationListEn',
    name: 'CitationListEn',
    component: () => import('@/views/Citation/citationTraceListEn.vue')
  },
  // 引文追踪个人中心
  {
    path: '/citationPersonal',
    name: 'CitationPersonal',
    component: () => import('@/views/Citation/citationTracePersonal.vue')
  },
  // scholarcy介绍页面
  {
    path: '/scholarcyDes',
    name: 'ScholarcyDes',
    component: () => import('@/views/scholarcy/scholarcyDes.vue')
  },
  // scholarcy上传页面
  {
    path: '/scholarcyUpload',
    name: 'ScholarcyUpload',
    component: () => import('@/views/scholarcy/scholarcyUpload.vue')
  },
  // scholarcy列表页面
  {
    path: '/scholarcyList',
    name: 'ScholarcyList',
    component: () => import('@/views/scholarcy/scholarcyList.vue')
  },
  // writeful上传页面
  {
    path: '/writefulUpload',
    name: 'WritefulUpload',
    component: () => import('@/views/writeful/writefulUpload.vue')
  },
  // writeful列表页面
  {
    path: '/writefulList',
    name: 'WritefulList',
    component: () => import('@/views/writeful/writefulList.vue')
  },
  // 选刊投稿页面
  {
    path: '/jguide',
    name: 'jguide',
    component: () => import('@/views/jguide/jguide.vue')
  },
  // 智能問答
  {
    path: '/askQuestion',
    name: 'askQuestion',
    component: () => import('@/views/askQuestion/askQuestion.vue')
  },
  // 我的
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('@/views/Personal/personal.vue')
  },
  {
    path: '/read',
    name: 'Read',
    component: () => import('@/views/Pdf/pdf.vue')
  },
  {
    path: '/qinli_app',
    name: 'qinli_app',
    component: () => import('@/views/qinli_app/qinli_app.vue')
  },
  {
    path: '/pagesIntermediate',
    name: 'pagesIntermediate',
    component: () => import('@/views/qinli_app/pagesIntermediate.vue')
  },
  {
    path: '/booksSet',
    name: 'booksSet',
    component: () => import('@/views/booksSet/booksSet.vue')
  },
  // 404
  {
    path: '/*',
    name: '404',
    component: () => import('@/views/404/index.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  // if (to.path === '/enroll') {
  //   console.log("a")
  //   return next()
  // } else 
  // console.log(to.path)
  // if(localStorage.getItem("token")){

  // } 
  if(to.path === '/single'){
    return next()
  }
  if (to.path === '/qinli_app' || to.path === '/pagesIntermediate' || to.path === '/booksSet') {
    return next()
  }
  if (to.path === '/') {
    // window.sessionStorage.removeItem("enroll");
    if (localStorage.getItem("token")) {
      return next('/home')
    } else {
      next()
    }

  }
  if (to.path !== '/' && !localStorage.getItem("token")) {
    console.log()
    if (to.path !== '/enroll') {
      if (to.query && to.query.webPage == '2' && to.path == '/search') {
        window.sessionStorage.setItem("h5", true)
      }
      if (to.path == '/askQuestion') {
        return next()
      }
      // window.sessionStorage.removeItem("enroll");
      window.sessionStorage.setItem("routing", to.path)
      if (to.query) {
        window.sessionStorage.setItem("routingQuery", JSON.stringify(to.query))
      }
      if (to.path == '/journalsDetail' || to.path == '/essay') {
        window.sessionStorage.setItem("journals_detail", JSON.stringify(to.query))
      }
      if (to.query.webPage) {
        window.sessionStorage.setItem("linkPage", to.query.webPage)
      }
      if (to.query.origins) {
        window.sessionStorage.setItem("linkOrigins", to.query.origins)
      }
      return next('/')
    } else {

      if (!localStorage.getItem("token")) {
        // return next('/')
        // window.localStorage.setItem("token", '')
        window.sessionStorage.setItem("enroll_id", to.query.chatroom)
        window.sessionStorage.setItem("enroll", 1)
        return next()
      } else {
        window.sessionStorage.setItem("enroll_id", to.query.chatroom)
        window.sessionStorage.setItem("enroll", 1)
        return next()
      }

    }

  } else if (to.path === '/jguide') {
    if (localStorage.getItem('selected_journals') == 'true') {
      next()
    } else {
      return next('/home')
    }

  } 
  // else if (to.query && to.query.webPage == '2' && to.path == '/search') {
  //   window.location.href = "https://qinglih5.newacademic.net/";
  //   window.localStorage.setItem("isshow", "0");
  // } 
  else {
    next()
  }

})

export default router