import axios from 'axios'
// import store from "../router/index"
import store from '@/store'
import VueRouter from 'vue-router'
import {
  Message
} from 'element-ui'
import router from '../router/index'

console.log(process.env.VUE_APP_API_BASE_URL)
// 创建axios 实例
const service = axios.create({
  // baseURL: process.env.baseUrl, // api的base_url111
  // baseURL: "http://62.234.162.28:8001/api/",
  // baseURL: "http://192.168.66.18:8007/api/",
  // baseURL: "https://ql.dic.cool/api/",
  // baseURL: "https://xcx.newacademic.net/api/",
  // baseURL:'https://ql-test.newacademic.net/api/' ,
  // baseURL:'https://qingli.newacademic.net/api/' ,
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 50000 // 请求超时时间  
})
// localStorage.setItem("baseURL", 'http://192.168.66.18:8007/api/');
// localStorage.setItem("baseURL", 'https://ql.dic.cool/api/'); 
// window.localStorage.setItem("baseURL", 'http://62.234.162.28:8001/api/');
window.localStorage.setItem("baseURL", 'https://xcx.newacademic.net/api/');
// window.localStorage.setItem("baseURL",'https://ql-test.newacademic.net/api/');
// console.log(service)
service.interceptors.request.use((config) => {
  // 动态设置请求头
  config.headers.common = {
    // Authorization: 'Bearer ' + store.getCookie('token')
    token: localStorage.getItem('token'),
    // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDE5NzQ4OTAsImlhdCI6MTY0MTM3MDA4MCwiZGF0YSI6eyJpZCI6MTY0LCJlbWFpbCI6IjEwMTM3MTU0NzNAcXEuY29tIiwicGhvbmUiOiIxODR4eHh4MDYwNyIsIm5hbWUiOiJcdTVmOTBcdTU2MDlcdTY1NGYiLCJmYWN1bHR5IjoiXHU1ZjAwXHU1M2QxIiwic3RhdHVzIjowfX0.629NEWBqMr2-unocUDSScxUQAhr0G3HgitzdnM4hw7E'
  }
  if (config.url == !'literature/sort/') {
    if (config.method === 'post') {
      config.data = {
        ...config.data,
        t: Date.parse(new Date())
      }
    } else if (config.method === 'get') {
      config.params = {
        ...config.params,
        t: Date.parse(new Date())
      }
    }
  }
  return config
}, (err) => {
  return Promise.reject(err)
})

service.interceptors.response.use((res) => {
  const response = res.data
  // console.log('response', response)
  if (response.error_code == 0 || res.config.url === 'literature/new_sort/') {
    // 请求成功
    return res
  } else if (response.error_code == 400) {
    // console.log(response);
    // token为空、失效 跳转登录页
    localStorage.clear();

    // store.clearToken('token')
    // store.clearToken('email')
    // store.clearToken('faculty')
    // store.clearToken('id')
    // store.clearToken('name')
    // store.clearToken('phone')
    // store.clearToken('status')
    // console.log(window.sessionStorage.getItem('mes'))


    if (window.sessionStorage.getItem('mes')) {
      let mes = JSON.parse(window.sessionStorage.getItem('mes'))
      if (mes.sing_on) {
        router.push({
          name: "LoginSingle",
        });
      } else {
        router.push({
          name: "Login",
        });
      }
    } else {
      router.push({
        name: "Login",
      });
    }
    Message.error({
      type: 'error',
      message: response.msg
    });
    // return Promise.reject(new Error(response.msg || 'Error'))

  } else {
    // 提示错误信息
    if (response.msg != '今日已提交过此需求') {
      Message.error({
        type: 'error',
        message: response.msg
      });
    }

    return Promise.reject(new Error(response.msg || 'Error'))
  }
}, (err) => {
  return Promise.reject(err)
})

export default service