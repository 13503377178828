import request from '@/utils/request'
import qs from 'qs'
/**
 * 需求查看
 */
export function demandCheck(data,query) {
  return request({
    url: 'literature/get_user_demand/',
    method: 'post',
    params: query,
    data
  })
}

// 新查看用户需求记录
export function newDemandCheck(data,query) {
  return request({
    url: 'literature/new_get_user_demand/',
    method: 'post',
    params: query,
    data
  })
}

/**
 * 提交需求
 */
export function submit(data) {
  return request({
    url: 'literature/demand/',
    method: 'post',
    data
  })
}

// 提交中文书需求
export function partChBook(data) {
  return request({
    url: 'literature/demand/',
    method: 'post',
    data
  })
}
// 重新提交任务至人工处理
export function manual(data) {
  return request({
    url: 'literature/reprocess/',
    method: 'post',
    data
  })
}
// 删除需求
export function Deletes(query) {
  return request({
    url: 'literature/deletedemand/',
    method: 'get',
    params: query
  })
}
// 批量删除需求
export function batchDelete(query) {
  return request({
    url: 'literature/deletedemand/',
    method: 'post',
    data
  })
}
// 删除需求
export function newDeletes(query) {
  return request({
    url: 'literature/new_deletedemand/',
    method: 'get',
    params: query
  })
}
// 需求识别
export function Identify(data) {
  return request({
    url: 'literature/sort/',
    method: 'post',
    data:qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 02-新查看分类列表
export function categoryList(query) {
  return request({
    url: 'literature/new_category/',
    method: 'get',
    params: query
  })
}
// 返回未读的已完成需求数量
export function unreadNumber(query) {
  return request({
    url: 'literature/cat_unread/',
    method: 'get',
    params: query
  })
}
// 返回未读的已完成需求数量
export function newUnreadNumber(query) {
  return request({
    url: 'literature/new_cat_unread/',
    method: 'get',
    params: query
  })
}

// 需求已读 - 新
export function newRead(data) {
  return request({
    url: 'literature/new_demand_read/',
    method: 'post',
    data
  })
}

// 需求已读
export function read(data) {
  return request({
    url: 'literature/demand_read/',
    method: 'post',
    data
  })
}


// 聊天记录查询
export function messagerecordData(query) {
  return request({
    url: 'user/messagerecord/',
    method: 'get',
    params: query
  })
}
// 聊天记录详情
export function messageDetail(data) {
  return request({
    url: 'user/message_detail/',
    method: 'post',
    data
  })
}


// 获取下载链接类型
export function getfileformat(data) {
  return request({
    url: 'user/fileformat/',
    method: 'post',
    data
  })
}

// 关联译采任务
export function yiCaiTask_id(query) {
  return request({
    url: 'literature/yicai_task_id/',
    method: 'get',
    params: query
  })
}

// 新关联译采任务
export function newYiCaiTask_id(query) {
  return request({
    url: 'literature/new_yicai_task_id/',
    method: 'get',
    params: query
  })
}

// 错误反馈
export function errorFeedback(data) {
  return request({
    url: 'literature/new_reprocess/',
    method: 'post',
    data
  })
}

// 新文本分类
export function newSortPost(data) {
  return request({
    url: 'literature/new_sort/',
    method: 'post',
    data:qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 新批量提交需求
export function newDemandPost(data) {
  return request({
    url: 'literature/new_demand/',
    method: 'post',
    data
  })
}